import './App.css';
import Chat, {Bubble, toast, useMessages} from '@chatui/core';
import '@chatui/core/dist/index.css';
import '@chatui/core/es/styles/index.less';
import React, {useState} from 'react';
import axios from "axios";
import ReactMarkdown from 'react-markdown'

const defaultQuickReplies = [
    {
        name: '清空会话',
        isNew: false,
        isHighlight: true,
    }
];


const initialMessages = [
    {
        type: 'text',
        content: {text: '我使用最先进的自然语言模型，您可以与我聊天体验AI的风采，请记住我们的官网：www.xszys.pro，如果您在聊天过程中遇到了回复内容截断的问题，发送"继续"两个字可以得到接下来的内容。'},
        user: {avatar: 'http://jx.xszys.tw/upload/xsz.png'},
    },
];

const lastPromptList = [];

function App() {
    const {messages, appendMsg, setTyping} = useMessages(initialMessages);
    const [percentage, setPercentage] = useState(0);


    function clearQuestion(requestText) {
        return requestText
    }

    function clearReply(reply) {
        return reply
    }

    function handleSend(type, val) {
        if (percentage > 0) {
            toast.show('正在等待上一次回复，请稍后')
            return;
        }
        if (type === 'text' && val.trim()) {
            appendMsg({
                type: 'text',
                content: {text: val},
                position: 'left',
                user: {avatar: '/default_avatar.png'},
            });
            setTyping(true);
            setPercentage(10);
            onGenCode(val);
        }
    }

    function renderMessageContent(msg) {
        const {type, content} = msg;

        switch (type) {
            case 'text':
                let text = content.text
                return <Bubble><ReactMarkdown children={text}/></Bubble>;
            default:
                return null;
        }
    }

    function onGenCode(question) {
        question = clearQuestion(question)
        let submitQuestion = question;
        let url = "completion"
        axios.post(url,
            {
                "lastPromptList": lastPromptList,
                "question": submitQuestion,
            }).then((response) => {
                let reply = clearReply(response.data.data)
                //将本次的问题和回复，记录到列表中
                //如果发的不是继续，则记录
                lastPromptList.push({
                    "role":"user",
                    "content":submitQuestion
                });
                lastPromptList.push({
                    "role": "assistant",
                    "content": reply
                });

                appendMsg({
                    type: 'text',
                    content: {text: reply},
                    user: {avatar: 'http://jx.xszys.tw/upload/xsz.png'},
                });
                setPercentage(0);
            }
        ).catch(err => {
            setPercentage(0);
            // 错误处理
            toast.show("请求出错，" + err.response.data.errorMsg)
        });
    }

    return (
        <Chat
            messages={messages}
            renderMessageContent={renderMessageContent}
            onSend={handleSend}
        />
    );
}

export default App;
